

const AuctionsPage = () => {


    return (
        <>AUCTIONS</>
    )
}

export default AuctionsPage;